import React from "react";
import { Svgs, Pngs } from "../../assets/images";
import {
  GridContent,
  PageWrapper,
  SectionTitle,
  TeamGrid,
  TeamGridAvatar,
  TeamGridAvatarTitle,
  TeamGridDescription,
  TeamGridName,
  TeamGridSocial,
} from "./styles";

const data = [
  {
    id: 1,
    avatar: Pngs.FOUNDER,
    name: "Ryan R.",
    title: "CEO",
    description:
      "Founder of Sabertooth Motor Club and creative director. Ryan plans the future and mobilizes the team for the present.",
    twitter: "SMC23RR",
    linkedin: "ryanronquillo",
    instagram: "SMC23RR",
  },
  {
    id: 2,
    avatar: Pngs.VISHNUGP,
    name: "Vishnu G.",
    title: "engineer",
    description:
      "Backend engineer overseeing the plumbing for our web and mobile platforms. ",
    twitter: "vishnu_g_p",
    linkedin: "",
    instagram: "gp.vishnu",
  },
  {
    id: 3,
    avatar: Pngs.PITAZ,
    name: "Peter O.",
    title: "engineer",
    description:
      "Frontend engineer building everything you see on our web and mobile platforms. ",
    twitter: "_pitaz",
    linkedin: "",
    instagram: "pitaz_",
  },
  {
    id: 4,
    avatar: Pngs.ABHINAV,
    name: "Abhinav S.",
    title: "UX/UI Designer",
    description:
      "UX/UI designer making sure our web and mobile designs and branding are the best in the game. ",
    twitter: "Sainiabhinav8",
    linkedin: "",
    instagram: "mixedreelings",
  },
  {
    id: 5,
    avatar: Pngs.ALIVIA,
    name: "Alivia S.",
    title: "3d Artist",
    description:
      "3D artist with a hand in all things design and animation. She makes your avatar can dance.",
    twitter: "kingxikea",
    linkedin: "",
    instagram: "_kingikea",
  },
  {
    id: 6,
    avatar: Pngs.CHRISTOPHER,
    name: "Christopher S.",
    title: "3d Artist",
    description:
      "3D artist making sure your metaverse avatar looks fresh to death.",
    twitter: "",
    linkedin: "",
    instagram: "chris_serrano_",
  },
  {
    id: 7,
    avatar: Pngs.JUSTIN,
    name: "Justin F.",
    title: "3d Artist",
    description:
      "3D artist and Discord community manager. He keeps your avatars fly and the community booming. ",
    twitter: "finkgraphics",
    linkedin: "",
    instagram: "Finktheartist",
  },
  {
    id: 8,
    avatar: Pngs.NOAH,
    name: "Noah A.",
    title: "3d Artist",
    description:
      "3D artist who makes the Sabertooth the best you've ever seen and keeps them dancing with animated videos. ",
    twitter: "yinkaart",
    linkedin: "",
    instagram: "yinkaart",
  },
  {
    id: 9,
    avatar: Pngs.PABLO,
    name: "Pablo R.",
    title: "3d Artist",
    description:
      "3D engineer building your home in the metaverse with the most beautiful, unreal environments you'll ever see in the metaverse. ",
    twitter: "ArismendiRabe ",
    linkedin: "",
    instagram: "cacatoxica",
  },
];

const MeetTheTeam = () => {
  return (
    <PageWrapper className="team">
      <SectionTitle>Meet the smc Team</SectionTitle>
      <TeamGrid>
        {data.map((i, idx) => (
          <GridContent key={i} className={idx === 0 && 'first-item'}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <TeamGridAvatar src={i.avatar} alt="" />
              <TeamGridAvatarTitle>{i.name}</TeamGridAvatarTitle>
              <TeamGridName>{i.title}</TeamGridName>
              <TeamGridDescription>{i.description}</TeamGridDescription>
            </div>
            <TeamGridSocial>
              {i?.twitter && (
                <a
                  href={`https://www.twitter.com/${i.twitter}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.TWITTER_BLACK className="icon" />
                </a>
              )}
              {i?.linkedin && (
                <a
                  href={`https://www.linkedin.com/in/${i.linkedin}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.LINKEDIN_BLACK className="icon" />
                </a>
              )}

              {i?.instagram && (
                <a
                  href={`https://www.instagram.com/${i.instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.INSTAGRAM_BLACK className="icon" />
                </a>
              )}
            </TeamGridSocial>
          </GridContent>
        ))}
      </TeamGrid>
    </PageWrapper>
  );
};

export default MeetTheTeam;