import styled, { css } from "styled-components";
import { Pngs } from "../../assets/images";

const reusableCSS = css`
  .content {
    padding: 120px 197px;
    /* width: 40%; */
  }
  .title {
    font-family: Cinzel Decorative;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    /* line-height: 63px; */
    color: #ffffff;
    margin-bottom: 20px;
    text-transform: uppercase;
    width: 586px;
  }
  .se-text {
    width: 475px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1194px) {
    display: flex;
    height: unset;
    padding: 50px 0;
    justify-content: center;
    .content {
      padding: 120px 20px;
    }
    .title {
      font-size: 32px;
      text-align: center;
      width: 100%;
    }
    .se-text {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 20px;
      text-align: center;
    }
  }
`;

export const PageWrapper = styled.div`
  /* background: #000000; */
`;

export const SectionOne = styled.div`
  /* background: #000000; */
  position: relative;
  /* height: 100vh; */
  /* width: 100%; */
  background-image: url(${Pngs.BG_1});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 175px 183px;

  .vr-image {
    position: absolute;
    width: 686px;
    height: 690px;
    bottom: 0;
    right: 180px;
  }

  .left-content {
    width: 30%;
    margin-top: 45px;
  }
  .le-text-1 {
    font-family: Cinzel Decorative;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 59px;

    color: #ffffff;
  }

  .le-text-2 {
    font-family: Cinzel Decorative;
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  .le-text-3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    padding: 32px 0 0;
  }
  .bold {
    font-weight: 800;
  }
  .underline {
    text-decoration: underline;
  }

  @media screen and (max-width: 1194px) {
    padding: 30px 30px 80px;
    .left-content {
      width: 100%;
      margin-top: 45px;
    }

    .le-text-1 {
      font-size: 25px;
    }
    .le-text-2 {
      font-size: 16px;
    }
    .bold {
      font-weight: 800;
    }
    .underline {
      text-decoration: underline;
    }
  }
`;

export const Button = styled.button`
  /* background: #000000; */
  background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  box-shadow: 0px 8px 36px rgba(255, 255, 255, 0.27);
  border-radius: 36px;
  padding: 15px 50px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 48px;
  text-transform: uppercase;
  @media screen and (max-width: 1194px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding: 13px 50px;
  }
`;

export const WhiteButton = styled.button`
  background: #ffffff;
  box-shadow: 0px 8px 36px rgba(255, 255, 255, 0.27);
  border-radius: 36px;
  padding: 10px 50px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 30px; */
  color: #1e1e1e;
  margin-top: 18px;

  .sub-bt-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 30px; */
    color: #bebebe;
  }
`;

export const SectionTwo = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 80vh;
  background-image: linear-gradient(
      91.29deg,
      #000000 -26.79%,
      rgba(0, 0, 0, 0) 82.7%
    ),
    url(${Pngs.BG_7});
  background-repeat: no-repeat;
  background-size: cover;

  ${reusableCSS}
`;

export const SectionThree = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  background-image: linear-gradient(
      to left,
      #000000 -10.1%,
      rgba(0, 0, 0, 0) 80.63%
    ),
    url(${Pngs.BG_7});
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  height: 80vh;
  ${reusableCSS}
`;

export const TimeTravelSection = styled.div`
  display: flex;
  height: 80vh;
  justify-content: flex-end;
  position: relative;
  background-image: linear-gradient(
      to left,
      #000000 -10.1%,
      rgba(0, 0, 0, 0) 60.63%
    ),
    url(${Pngs.BG_2});
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  /* height: 620px; */
  ${reusableCSS}
`;

export const ResurrectSection = styled.div`
  display: flex;
  height: 80vh;
  justify-content: flex-start;
  position: relative;
  background-image: linear-gradient(
      91.29deg,
      #000000 -26.79%,
      rgba(0, 0, 0, 0) 82.7%
    ),
    url(${Pngs.BG_3});
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  /* height: 620px; */
  ${reusableCSS}
`;

export const ForeverSection = styled.div`
  display: flex;
  height: 80vh;
  justify-content: flex-end;
  position: relative;
  background-image: linear-gradient(
      to left,
      #000000 -5.1%,
      rgba(0, 0, 0, 0) 90.63%
    ),
    url(${Pngs.CAR_BG});
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  /* height: 620px; */
  ${reusableCSS}
`;

export const SkyScrapperSection = styled.div`
  display: flex;
  height: 80vh;
  justify-content: flex-start;
  position: relative;
  background-image: linear-gradient(
      91.29deg,
      #000000 -26.79%,
      rgba(0, 0, 0, 0) 82.7%
    ),
    url(${Pngs.BG_6});
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  /* height: 620px; */
  ${reusableCSS}
`;

export const CondoSection = styled.div`
  display: flex;
  height: 80vh;
  justify-content: flex-end;
  position: relative;
  background-image: linear-gradient(
      to left,
      #000000 -10.1%,
      rgba(0, 0, 0, 0) 60.63%
    ),
    url(${Pngs.BG_6});
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  /* height: 620px; */
  ${reusableCSS}/* @media screen and (max-width: 1194px) {
    background-image: linear-gradient(
        to left,
        #000000 -10.1%,
        rgba(0, 0, 0, 0.8) 60.63%
      ),
      url("https://res.cloudinary.com/doibwtx2d/image/upload/v1641392399/bg-11_necofd.png");
  } */
`;

export const Mobile = styled.div`
  display: none;
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Desktop = styled.div`
  /* display: flex;
  flex-direction: column; */
  @media screen and (max-width: 1194px) {
    display: none;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;

  position: relative;
  margin-bottom: -5px;
  background: linear-gradient(
    to bottom,
    #000000 50.1%,
    rgba(0, 0, 0, 0) 90.63%
  );
  .mute-wrapper {
    position: absolute;
    bottom: 20px;
    right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mute {
    cursor: pointer;
  }
  .mute-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 32px;

    text-align: center;

    color: #fff;
  }
  .vr-image {
    position: absolute;
    width: 686px;
    height: 690px;
    bottom: 0;
    right: 180px;
  }

  .left-content {
    width: 30%;
    margin-top: 45px;
    position: absolute;
    top: 320px;
    left: 100px;
  }
  .le-text-1 {
    font-family: Cinzel Decorative;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    /* line-height: 59px; */

    color: #ffffff;
  }

  .le-text-2 {
    font-family: Cinzel Decorative;
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  .le-text-3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    padding: 32px 0 0;
  }
  .bold {
    font-weight: 800;
  }
  .underline {
    text-decoration: underline;
  }

  @media screen and (max-width: 1194px) {
    /* padding: 30px 30px 80px; */
    .left-content {
      width: 90%;
      margin-top: 180px;
      top: 130px;
      left: 10px;
    }
    .react-player {
      width: 100%;
      height: 100vh !important;
      object-fit: cover !important;
    }
    .text-wrapper {
      margin-top: 24px;
    }
    video {
      object-fit: cover;
    }
    .le-text-1 {
      font-size: 25px;
    }
    .le-text-2 {
      font-size: 16px;
    }
    .le-text-3 {
      padding-top: 15px;
    }
    .bold {
      font-weight: 800;
    }
    .underline {
      text-decoration: underline;
    }
    .mute-wrapper {
      bottom: 10px;
      right: 10px;
    }
  }
`;

export const SMCWrapper = styled.div`
  /* background: #000000; */
  padding: 120px 100px;
  position: relative;
  .smc-text {
    font-family: Cinzel Decorative;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 65px;
    width: 45%;
    color: #000000;
  }
  .image {
    position: absolute;
    right: 110px;
    bottom: 0;
  }
  @media screen and (max-width: 1194px) {
    padding: 56px 10px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .smc-text {
      font-family: Cinzel Decorative;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      width: 70%;

      text-align: center;

      color: #000000;
    }
    .image {
      position: unset;
      right: 0px;
      /* bottom: 0; */
      width: 100%;
      object-fit: cover;
    }
  }
`;
