import React, { forwardRef, useEffect, useState } from "react";
import { Link as LogoLink } from "react-router-dom";
// import { Link } from "react-router-dom";
import { Svgs } from "../../assets/images";
import {
  IconsWrapper,
  MenuIconWrapper,
  MobileNav,
  MPageTitle,
  NavWrapper,
  PageTitle,
  Mobile,
  DiscordButton,
} from "./styles";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const handleNav = () => {
    window.open("https://discord.gg/sabertoothmotorclub", "_blank");
  };

  return (
    <NavWrapper colorChange={colorChange} open={open}>
      <div className="nav">
        {open ? (
          <MenuIconWrapper>
            <Svgs.CLOSE onClick={() => setOpen(false)} />
          </MenuIconWrapper>
        ) : (
          <MenuIconWrapper>
            <Svgs.MENU onClick={() => setOpen(true)} />
          </MenuIconWrapper>
        )}
        <div className="logo-section">
          <LogoLink to="/">
            <Svgs.LOGO width={90} height={65} className="icon" />
          </LogoLink>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="saber-tooth">Sabertooth </p>
            <p className="mclub">Motor Club</p>
          </div>
        </div>
        <div className="menu">
  
        </div>
        <IconsWrapper>
          <a
            href="https://www.instagram.com/sabertoothmotorclub"
            target="_blank"
            rel="noreferrer"
          >
            <Svgs.INSTAGRAM />
          </a>

          <a
            href="https://www.twitter.com/sabertoothMC23"
            target="_blank"
            rel="noreferrer"
          >
            <Svgs.TWITTER />
          </a>
          {!open && (
            <DiscordButton onClick={handleNav}>
              <span>Join our Discord </span>
              <Svgs.DISCORD />
            </DiscordButton>
          )}
        </IconsWrapper>
      </div>
      <Mobile>
        {open && (
          <MobileNav>


          </MobileNav>
        )}
      </Mobile>
    </NavWrapper>
  );
};

export default Nav;
