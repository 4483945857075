import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { Pngs, Svgs } from "../../assets/images";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import MeetTheTeam from "../MeetTheTeam";
import {
  Button,
  Desktop,
  ForeverSection,
  Mobile,
  PageWrapper,
  ResurrectSection,
  SectionThree,
  SkyScrapperSection,
  SMCWrapper,
  TimeTravelSection,
  VideoWrapper,
} from "./styles";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const containerRef = useRef();

  const toggleSound = () => setIsMute(!isMute);
  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    const refCurrent = containerRef.current;
    const observer = new IntersectionObserver(callbackFunction, options);
    if (refCurrent) observer.observe(refCurrent);

    return () => {
      if (refCurrent) observer.unobserve(refCurrent);
    };
  }, [containerRef]);
  return (
    <>
      <Nav />
      <PageWrapper>
        <VideoWrapper ref={containerRef}>
          <Header />
          <div className="left-content">
            <p className="le-text-1">LIVE AN EXPERIENCE</p>
            <div className="text-wrapper">
              <p className="le-text-3">
                <span className="bold">Chronos</span> will offer you an{" "}
                <span className="underline">unique immersive expereince</span>{" "}
                in this new world
              </p>
              <p className="le-text-3">
                You will be able to embody{" "}
                <span className="underline">your own character</span> and live a
                unique adventure.
              </p>
            </div>
          </div>
          <ReactPlayer
            className="react-player"
            url={
              "https://res.cloudinary.com/doibwtx3d/video/upload/v1645107531/smc/Image_from_iOS_odlbb3.mp4"
            }
            width="100%"
            height="100%"
            muted={isMute}
            controls={false}
            playing={isVisible}
            loop
          />
          <div className="mute-wrapper">
            {isMute ? (
              <Svgs.MUTE onClick={toggleSound} className="mute" />
            ) : (
              <Svgs.UNMUTE onClick={toggleSound} className="mute" />
            )}

            <p className="mute-text">Tap to {isMute ? "unmute" : "mute"}</p>
          </div>
        </VideoWrapper>
        <SMCWrapper>
          <p className="smc-text">Chronos is home to sabertooth motor club</p>
          <Button>Visit SMC</Button>
          <img className="image" src={Pngs.MD_JUMBO_PIC} alt="" />
        </SMCWrapper>
        <TimeTravelSection>
          <div className="content">
            <p className="title">Chronos Condos</p>
            <p className="se-text">
              Travel back in time and get an immersive, hyper-realistic
              experience like none other. Attend a metaverse recreation of
              historical events including concerts, speeches, interviews, and
              more. Walk through the streets and crowd and experience
              history…for the first time.
            </p>
          </div>
        </TimeTravelSection>
        <ResurrectSection>
          <div className="content">
            <p className="title">EXPLORE WITH FLYING DRAGONS</p>
            <p className="se-text">
              Create and interact with a hyper-realistic version of family
              members, friends, historical figures, and more who have passed.
            </p>
          </div>
        </ResurrectSection>
        <ForeverSection>
          <div className="content">
            <p className="title">VIRTUAL CARS</p>
            <p className="se-text">
              Whether you plan on experiencing a metaverse road trip, or pushing
              top speeds in a race, this is your chance to get rare and amazing
              cars to add to your collection.
            </p>
          </div>
        </ForeverSection>
        <SkyScrapperSection>
          <div className="content">
            <p className="title">VIRTUAL LAND</p>
            <p className="se-text">
              Experience luxury condominiums in the metaverse.
            </p>
            <p className="se-text">
              Our residential skyscrapers are elegantly designed for those who
              want to experience fine living in the Chronos metaverse. From
              studios to full floor penthouses, you will get amazing panoramic
              views, your choice of finishes, and much more.
            </p>
          </div>
        </SkyScrapperSection>
        <SectionThree>
          <div className="content">
            <p className="title">VIRTUAL CONCERTS</p>
            <p className="se-text">
              Experience concerts in the most immersive way. Walk through the
              crowd and get up close to your favorite artists.
            </p>
          </div>
        </SectionThree>
        <Desktop>
          <MeetTheTeam />
        </Desktop>
        <Mobile>
          <MeetTheTeam />
        </Mobile>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Home;
