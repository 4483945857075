import BG_1 from './pngs/bg-1.png';
import BG_2 from './pngs/bg-2.png';
import BG_3 from './pngs/bg-3.png';
import BG_4 from './pngs/bg-4.png';
import BG_5 from './pngs/bg-5.png';
import BG_6 from './pngs/bg-6.png';
import BG_7 from './pngs/bg-7.png';
import CAR_BG from './pngs/car-bg.png';
import FOUNDER from './pngs/founder.png';
import VISHNUGP from './pngs/vishnu.png';
import PITAZ from './pngs/pitaz.png';
import ABHINAV from './pngs/abhinav.png';
import ALIVIA from './pngs/alivia.png';
import CHRISTOPHER from './pngs/christopher.png';
import JUSTIN from './pngs/justin.png';
import NOAH from './pngs/noah.png';
import PABLO from './pngs/pablo.png';
import MD_JUMBO_PIC from './pngs/avatars.png';


import {ReactComponent as LOGO} from './svgs/logo.svg';
import {ReactComponent as INSTAGRAM} from './svgs/instagram.svg';
import {ReactComponent as TWITTER} from './svgs/twitter.svg';
import {ReactComponent as TWITTER_BLACK} from './svgs/twitter-black.svg';
import {ReactComponent as LINKEDIN_BLACK} from './svgs/linkedin-black.svg';
import {ReactComponent as INSTAGRAM_BLACK} from './svgs/instagram-black.svg';
import {ReactComponent as MUTE} from './svgs/mute.svg';
import {ReactComponent as UNMUTE} from './svgs/unmute.svg';
// import {ReactComponent as LOGO} from './svgs/smc-logo.svg';
import {ReactComponent as MENU} from './svgs/menu.svg';
import {ReactComponent as CLOSE} from './svgs/close.svg';
import {ReactComponent as DISCORD} from './svgs/discord.svg';


export const Svgs = {
  LOGO,
  INSTAGRAM,
  TWITTER,
  TWITTER_BLACK,
  LINKEDIN_BLACK,
  INSTAGRAM_BLACK,
  MUTE,
  UNMUTE,
  MENU,
  CLOSE,
  DISCORD
}

export const Pngs = {
  // MAN_VR,
  BG_1,
  BG_2,
  BG_3,
  BG_4,
  BG_5,
  BG_6,
  BG_7,
  CAR_BG,
  FOUNDER,
  VISHNUGP,
  PITAZ,
  ABHINAV,
  ALIVIA,
  CHRISTOPHER,
  JUSTIN,
  NOAH,
  PABLO,
  MD_JUMBO_PIC
}