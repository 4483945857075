import React from "react";
import { Svgs } from "../../assets/images";
import { Desktop } from "../../pages/Home/styles";
import { FooterWrapper, IconsWrapper, ReachOut, Terms } from "./styles";

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="desktop">
        <div className="content">
          <div className="cn-left">
            <p className="logo">Chronos</p>
            <p className="copyright">Copyright © 2021 Sabertooth Motor Club.</p>
          </div>
          <div className="cn-right">
          <ReachOut>Follow us</ReachOut>
              <IconsWrapper>
                <a
                  href="https://www.instagram.com/sabertoothmotorclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.INSTAGRAM />
                </a>
                {/* <Svgs.DISCORD /> */}
                <a
                  href="https://www.twitter.com/sabertoothMC23"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.TWITTER />
                </a>
                <a
                  href="https://discord.gg/sabertoothmotorclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.DISCORD />
                </a>
              </IconsWrapper>
              <Terms to="/terms">Terms & conditions</Terms>
              <Terms to="/">team@sabertoothmotorclub.com</Terms>
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="content">
          <div className="cn-left">
            <p className="logo">Chronos</p>
            <p className="copyright">Copyright © 2022 Sabertooth Motor Club.</p>
          </div>

          <div className="cn-right">
            <div>
            <ReachOut>Follow us</ReachOut>
              <IconsWrapper>
                <a
                  href="https://www.instagram.com/sabertoothmotorclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.INSTAGRAM />
                </a>
                {/* <Svgs.DISCORD /> */}
                <a
                  href="https://www.twitter.com/sabertoothMC23"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.TWITTER />
                </a>
                <a
                  href="https://discord.gg/sabertoothmotorclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.DISCORD />
                </a>
              </IconsWrapper>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <Terms to="/terms">Terms & conditions</Terms>
              <Terms to="/">team@sabertoothmotorclub.com</Terms>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
