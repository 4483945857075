import React from "react";
import { HeaderWrapper } from "./styles";

const Header = () => {
  return (
    <HeaderWrapper>
      <div className="container">
        <p className="big-text">Chronos</p>
        {/* <Svgs.LOGO width={122} height={123} /> */}
        {/* <p className="big-text">WORLD</p> */}
      </div>
      <p className="enter-text">ENTER IN THE NEW WORLD</p>
    </HeaderWrapper>
  );
};

export default Header;
