import styled from "styled-components";

export const HeaderWrapper = styled.div`
  /* background: #000000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 16px;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 55px;
  }
  /* background-color: #ffffff; */
  .big-text {
    font-family: Cinzel Decorative;
    font-style: normal;
    font-weight: bold;
    font-size: 90px;
    line-height: 110px;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 100px;
  }

  .enter-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 24px;
  }

  @media screen and (max-width: 1194px) {
    .big-text {
      font-size: 42px;
      line-height: 50px;
      margin-top: 70px;
    }
    .enter-text {
      margin-top: 20px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      color: #ffffff;
    }
  }
`;
