import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterWrapper = styled.div`
  background: #000000;
  display: flex;
  justify-content: flex-end;
  padding: 80px 140px 40px;
  color: #fff;
  position: relative;

  .content {
    display: flex;
    width: 100%;
    gap: 430px;
    justify-content: space-between;
  }

  .cn-left {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* left: 50%;
    right: 50%;
    bottom: 0; */
  }

  .cn-right {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* left: 50%;
    right: 50%;
    bottom: 0; */
  }

  .socials {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .contact {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    padding-bottom: 16px;

    color: #ffffff;
  }

  .terms {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-decoration-line: underline;
    color: #ffffff;
    margin-top: 42px;
  }
  .copyright {
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: center;
    margin-top: 20px;
  }
  .logo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 68px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .mobile {
    display: none;
    @media screen and (max-width: 1194px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .desktop {
    @media screen and (max-width: 1194px) {
      display: none;
    }
  }
  @media screen and (max-width: 1194px) {
    display: flex;
    justify-content: center;
    padding: 30px 20px;
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0;
    }

    .cn-right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
    }

    .terms {
      margin-top: 0;
      font-size: 14px;
    }
    .contact {
      font-size: 16px;
    }

    .logo {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 42px;
      line-height: 68px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 32px;
`;

export const ReachOut = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-bottom: 25px;
`;
export const Terms = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #ffffff;
  /* padding-top: 16px; */
  @media screen and (max-width: 1194px) {
    font-size: 16px;
  }
`;